import { useState, useEffect, EffectCallback, DependencyList, useRef } from 'react'

export function useDebounce<T = any>(value: T, delay: number): T {
	const [debouncedValue, setDebouncedValue] = useState(value)

	useEffect(
		() => {
			const handler = setTimeout(() => {
				setDebouncedValue(value)
			}, delay)

			return () => {
				clearTimeout(handler)
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[value],
	)

	return debouncedValue
}

type funcType = (...args: any) => void
export function useFunctionDebounce<T extends funcType>(f: T, delay: number): T {
	const timer = useRef<NodeJS.Timeout | null>(null)

	const debounceFunc = (...args: any[]) => {
		if (timer.current) clearTimeout(timer.current)

		timer.current = setTimeout(() => {
			f(...args)
		}, delay)
	}

	return debounceFunc as T
}

export function useDebounceEffect(
	effect: EffectCallback,
	delay: number,
	deps?: DependencyList,
): void {
	const timer = useRef<NodeJS.Timeout | null>(null)

	const debounceFunc = () => {
		if (timer.current) clearTimeout(timer.current)

		timer.current = setTimeout(() => {
			effect()
		}, delay)
	}

	useEffect(debounceFunc, deps)
}
